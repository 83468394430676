.rhap_container {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.rhap_time,
.rhap_progress-indicator,
.rhap_progress-filled,
.rhap_volume-indicator,
.rhap_additional-controls button,
.rhap_main-controls button,
.rhap_volume-controls button {
  color: #fce2a6;
}

.rhap_download-progress,
.rhap_volume-bar {
  background-color: #fe3d26 !important;
}
